import React, { useState } from 'react';

import { notification } from '../hocs/notification';
import { Link } from 'react-router-dom';
import Logo from './../../assets/img/logoW.png';

import { GiFarmer, GiCow } from 'react-icons/gi';
import { BsCloudSunFill, BsFileBarGraphFill, BsGearFill } from 'react-icons/bs';
import { FaHome, FaTractor, FaPowerOff, FaChartPie } from 'react-icons/fa';

import {
    Row,
    Col,

} from 'reactstrap';

function Header() {
    const [m1, setM1] = useState(false);
    const [m2, setM2] = useState(false);
    const [m3, setM3] = useState(false);
    const [m4, setM4] = useState(false);
    const [m5, setM5] = useState(false);
    const [m6, setM6] = useState(false);

    return (
        <div className='headerPage borderBottom'>
           <Row style={{padding:"4px 60px"}}>
                <Col xs={3}>
                    <img className='ms-3' height="60px" src={Logo} alt="logo" />
                </Col>
                <Col xs={9}>
                    
                    <div className='d-flex justify-content-end align-items-center h-100'>
                        
                        <div className='w-menu'>
                            <Link className='linkMenu' to='/home'><FaHome size={20} /></Link>
                        </div>

                        <div className='w-menu' onMouseEnter={() => setM1(true)} onMouseLeave={() => setM1(false)}>
                            <GiFarmer size={20} />
                                {m1 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Farm Activities</div>
                                            <Link className='linkPage' to='/traceability'> Traceability </Link>
                                            <Link className='linkPage' to='/meeting'> Meeting </Link>
                                            <Link className='linkPage' to='/Training'> Training </Link>
                                            <Link className='linkPage' to='/distribution'> Distribution </Link>
                                        </div>
                                    </div>
                                }
                         
                        </div>

                        <div className='w-menu' onMouseEnter={() => setM6(true)} onMouseLeave={() => setM6(false)}>
                            <GiCow size={25} />
                                {m6 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Livestock</div>
                                            <Link className='linkPage' to='/livestock-count'> Livestock Count </Link>
                                            <Link className='linkPage' to='/livestock-activity'> Livestock Activity </Link>
                                            <Link className='linkPage' to='/livestock-alert'> Livestock Alert </Link>
                                        </div>
                                    </div>
                                }
                         
                        </div>

                        {/* <div className='w-menu' onMouseEnter={() => setM2(true)} onMouseLeave={() => setM2(false)}>
                            <BsCloudSunFill size={20} />
                                {m2 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Advisory</div>
                                            <Link className='linkPage' to='/input'> Input </Link>
                                            <Link className='linkPage' to='/market'> Market </Link>
                                            <Link className='linkPage' to='/weather'> Weather </Link>
                                        </div>
                                    </div>
                                }
                         
                        </div> */}
                        <div className='w-menu' onMouseEnter={() => setM3(true)} onMouseLeave={() => setM3(false)}>
                            <FaTractor size={20} />
                                {m3 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Master</div>
                                            <Link className='linkPage' to='/farmer'> Farmer </Link>
                                            {/* <Link className='linkPage' to='/pg'> PG/VO/SHG </Link> */}
                                            <Link className='linkPage' to='/employee'> Employee </Link>
                                            <Link className='linkPage' to='/user-management'> User Management </Link>
                                            <Link className='linkPage' to='/crop-master'> Crop Master </Link>
                                            <Link className='linkPage' to='/crop-variety'> Crop Variety </Link>
                                        </div>
                                    </div>
                                }
                         
                        </div>
                        <div className='w-menu' onMouseEnter={() => setM4(true)} onMouseLeave={() => setM4(false)}>
                            <FaChartPie size={20} />
                                {m4 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Reports</div>
                                            <Link className='linkPage' to='/farmer-sowing-report'> Farmers Sowing Report </Link>
                                            <Link className='linkPage' to='/monthly-livestock-report'> Livestock Report (Monthly)</Link>
                                            <Link className='linkPage' to='/monthly-agriculture-report'> Agriculture Report (Monthly)</Link>
                                        </div>
                                    </div>
                                }
                         
                        </div>
                        <div className='w-menu' onMouseEnter={() => setM5(true)} onMouseLeave={() => setM5(false)}>
                            <BsGearFill size={20} />
                                {m5 &&
                                    <div>
                                        <div className='w-container'>
                                            <div className='menuHeader'>Setting</div>
                                            {/* <Link className='linkPage' to='/profile'> Profile </Link>
                                            <Link className='linkPage' to='/organisation'> Organisation </Link>
                                            <Link className='linkPage' to='/uom'> UOM </Link>
                                            <Link className='linkPage' to='/languages'> Language </Link> */}
                                            <Link className='linkPage' to='/pop'> POP (Package of practices) </Link>
                                            {/* <Link className='linkPage' to='/demand'> Demand </Link>
                                            <Link className='linkPage' to='/input-sale-purchase'> Input-sale-purchase </Link>
                                            <Link className='linkPage' to='/produce-sale-purchase'> Produce-sale-purchase </Link> */}
                                        </div>
                                    </div>
                                }
                         
                        </div>
                        
                        <div className='w-menu'>
                            <Link className='linkMenu' to='/' onClick={()=> sessionStorage.clear()}><FaPowerOff size={20} /></Link>
                        </div>

                        

                    </div>
                </Col>
           </Row>
        </div>
    )
}

export default Header;