import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import Loader from '../../component/utility/Loader';
import axios from 'axios';
import { mainurl } from '../../Mainurl';
import * as XLSX from 'xlsx/xlsx.mjs';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const MonthlyLivestockReport = () => {

    const [mode, setMode] = useState("1");
    
    const [searchDate, setSearchDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [cropData, setCropData] = useState([]);

    useEffect(() => {
        
    }, []);

    const fetch_livestock_data_handler = async () => {
        let month = searchDate.split("-")[1];
        let year = searchDate.split("-")[0];

        console.log("Month:", month, "Year:", year);

        setLoading(true);
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "month_id": month, "year_id": year }];
        axios({
            method: 'post',
            url: mainurl + 'Trace/trace_rpt1_liv',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let jsonOne = response.data;
                axios({
                    method: 'post',
                    url: mainurl + 'Trace/trace_rpt2_liv',
                    data: postData,
                    headers: { 'Content-Type': 'application/JSON', }
                })
                    .then(function (response) {
                        let jsonTwo = response.data;
                        //
                        // Function to merge the data
                        const mergeJsonData = (jsonOne, jsonTwo) => {
                            return jsonOne.map(farmer => {
                                // Find all crops for current farmer
                                const farmerCrops = jsonTwo.filter(crop => crop.farmer_id === farmer.farmer_code);

                                // Return farmer data with crop_list
                                return {
                                    ...farmer,
                                    crop_list: farmerCrops
                                };
                            });
                        };

                        // Merge the data
                        const result = mergeJsonData(jsonOne, jsonTwo);

                        // Display the result
                        console.log(result);



                        const cropData = getCropData(result);
                        const convertedData = convertFarmerData(result);
                        setTableData(convertedData);
                        setCropData(cropData);
                        console.log(cropData);
                        //
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                setLoading(false);
            });
    }

    function convertFarmerData(inputData) {
        //console.log(inputData);
        return inputData.map(farmer => {
            // Initialize the flattened farmer object with basic info
            let flattenedFarmer = {
                farmer_id: farmer.farmer_code,
                farmer_name: farmer.f_name,
                reg_no: farmer.f_reg_no,
                village: farmer.village_name,
                panchayat: farmer.panchayat,
                block: farmer.block_name,
                district: farmer.district_name,
                goat_count: farmer.goat_count,
                poultry_count: farmer.poultry_count,
                vaccination: farmer.vaccination,
                deworming: farmer.deworming,
                castration: farmer.castration,
                birth: farmer.birth,
                death: farmer.death,
                sale: farmer.sale,
                purchase: farmer.purchase,
            };

            // Process each crop in the crop_list
            farmer.crop_list.forEach((crop, index) => {
                const cropPrefix = crop.activity_desc.toLowerCase().replace(/\s/g, '_');

                // Add crop-specific fields with prefix
                flattenedFarmer[`${cropPrefix}_activity_desc`] = crop.activity_desc;
                flattenedFarmer[`${cropPrefix}_activity_date`] = crop.activity_date;
                flattenedFarmer[`${cropPrefix}_animal_count`] = crop.animal_count;
                flattenedFarmer[`${cropPrefix}_medicine_dose`] = crop.medicine_dose;
            });

            return flattenedFarmer;
        });
    }

    function getCropData(inputData) {

        //
        const cropData = inputData.flatMap(item =>
            item.crop_list.map(crop => crop.activity_desc.toLowerCase().replace(/\s/g, '_'))
        );
        const uniqueCropIds = [...new Set(cropData)];
        console.log("Unique crop IDs:", uniqueCropIds);
        //
        return uniqueCropIds;
    }

    const download_excel_handler = () => {
        if (tableData.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(tableData);
            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
            XLSX.writeFile(wb, "livestock_report.xlsx");
            let notify = notification({ message: "Excel downloaded successfully", type: 'success' });
            notify();
        } else {
            let notify = notification({ message: "No data found!", type: 'error' });
            notify();
        }
    }


    return (
        <>
            <Header />
            <Page title='Livestock Report (Monthly)' breadcrumbs='Home / Livestock Report (Monthly)'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md={2} className="mb-2">
                                                <Label for="month">Month - Year</Label>
                                                <Input type="month" name="month" id="month" placeholder="Month" onChange={(e) => setSearchDate(e.target.value) } />
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white mt-4' color='primary' size="sm" onClick={fetch_livestock_data_handler} block> Search </Button>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white mt-4' color='primary' size="sm" onClick={download_excel_handler} block> Download Excel </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Livestock Report <small className='text-muted'> </small></CardHeader>
                                <CardBody>
                                    <div className="overflow-auto">
                                        <table className="border-collapse table-auto">
                                            <thead>
                                                <tr className="bg-primary text-white">
                                                    <th className="border p-2">Farmer ID</th>
                                                    <th className="border p-2">Name</th>
                                                    <th className="border p-2">Reg No.</th>
                                                    <th className="border p-2">Village</th>
                                                    <th className="border p-2">Panchayat</th>
                                                    <th className="border p-2">Block</th>
                                                    <th className="border p-2">District</th>
                                                    <th className="border p-2">Goat Count</th>
                                                    <th className="border p-2">Poultry Count</th>
                                                    <th className="border p-2">Vaccination</th>
                                                    <th className="border p-2">Deworming</th>
                                                    <th className="border p-2">Castration</th>
                                                    <th className="border p-2">Birth</th>
                                                    <th className="border p-2">Death</th>
                                                    <th className="border p-2">Sale</th>
                                                    <th className="border p-2">Purchase</th>
                                                    {cropData?.map(crop => (
                                                        <React.Fragment key={crop}>
                                                            <th className="border p-2 bg-blue-50">{crop}</th>
                                                            <th className="border p-2">activity_date</th>
                                                            <th className="border p-2">animal_count</th>
                                                            <th className="border p-2">medicine_dose</th>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((farmer, index) => (
                                                    <tr key={farmer.farmer_id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                        <td className="border p-2">{farmer.farmer_id}</td>
                                                        <td className="border p-2">{farmer.farmer_name}</td>
                                                        <td className="border p-2">{farmer.reg_no}</td>
                                                        <td className="border p-2">{farmer.village}</td>
                                                        <td className="border p-2">{farmer.panchayat}</td>
                                                        <td className="border p-2">{farmer.block}</td>
                                                        <td className="border p-2">{farmer.district}</td>
                                                        <td className="border p-2">{farmer.goat_count}</td>
                                                        <td className="border p-2">{farmer.poultry_count}</td>
                                                        <td className="border p-2">{farmer.vaccination}</td>
                                                        <td className="border p-2">{farmer.deworming}</td>
                                                        <td className="border p-2">{farmer.castration}</td>
                                                        <td className="border p-2">{farmer.birth}</td>
                                                        <td className="border p-2">{farmer.death}</td>
                                                        <td className="border p-2">{farmer.sale}</td>
                                                        <td className="border p-2">{farmer.purchase}</td>
                                                        {cropData?.map(crop => (
                                                            <React.Fragment key={`${farmer.farmer_id}-${crop}`}>
                                                                <td className="border p-2 bg-blue-50">{farmer[`${crop}_activity_desc`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_activity_date`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_animal_count`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_medicine_dose`]}</td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>

            </Page>

            <Footer />
            {loading && <Loader />}

        </>
    )

}

export default MonthlyLivestockReport;