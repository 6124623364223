import React, { useState, useEffect } from 'react';
import { notification } from '../../component/hocs/notification';
import Header from '../../component/layout/Header';
import Footer from '../../component/layout/Footer';
import Page from '../../component/utility/Page';
import Loader from '../../component/utility/Loader';
import axios from 'axios';
import { mainurl } from '../../Mainurl';
import * as XLSX from 'xlsx/xlsx.mjs';

import { Row, Col, Card, CardHeader, CardBody, Form, Input, InputGroup, InputGroupText, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { FaEye, FaSearch, } from 'react-icons/fa';

const MonthlyAgricultureReport = () => {

    const [mode, setMode] = useState("1");
    
    const [searchDate, setSearchDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [cropData, setCropData] = useState([]);

    useEffect(() => {
        
    }, []);

    const fetch_livestock_data_handler = async () => {
        let month = searchDate.split("-")[1];
        let year = searchDate.split("-")[0];

        console.log("Month:", month, "Year:", year);

        setLoading(true);
        let postData = [{ "org_id": sessionStorage.getItem('org_id'), "month_id": month, "year_id": year }];
        axios({
            method: 'post',
            url: mainurl + 'Trace/trace_rpt1_ag',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                let jsonOne = response.data;
                axios({
                    method: 'post',
                    url: mainurl + 'Trace/trace_rpt2_ag',
                    data: postData,
                    headers: { 'Content-Type': 'application/JSON', }
                })
                    .then(function (response) {
                        let jsonTwo = response.data;
                        //
                        // Function to merge the data
                        const mergeJsonData = (jsonOne, jsonTwo) => {
                            return jsonOne.map(farmer => {
                                // Find all crops for current farmer
                                const farmerCrops = jsonTwo.filter(crop => crop.f_id === farmer.farmer_code);

                                // Return farmer data with crop_list
                                return {
                                    ...farmer,
                                    crop_list: farmerCrops
                                };
                            });
                        };

                        // Merge the data
                        const result = mergeJsonData(jsonOne, jsonTwo);

                        // Display the result
                        console.log(result);



                        const cropData = getCropData(result);
                        const convertedData = convertFarmerData(result);
                        setTableData(convertedData);
                        setCropData(cropData);
                        console.log(cropData);
                        //
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                setLoading(false);
            });
    }

    function convertFarmerData(inputData) {
        //console.log(inputData);
        return inputData.map(farmer => {
            // Initialize the flattened farmer object with basic info
            let flattenedFarmer = {
                farmer_id: farmer.farmer_code,
                farmer_name: farmer.f_name,
                reg_no: farmer.f_reg_no,
                village: farmer.village_name,
                panchayat: farmer.panchayat,
                block: farmer.block_name,
                district: farmer.district_name,
            };

            // Process each crop in the crop_list
            farmer.crop_list.forEach((crop, index) => {
                const cropPrefix = crop.crop_name.toLowerCase().replace(/\s/g, '_');

                // Add crop-specific fields with prefix
                flattenedFarmer[`${cropPrefix}_crop_name`] = crop.crop_name;
                flattenedFarmer[`${cropPrefix}_sow_date`] = crop.sowing_date;
                flattenedFarmer[`${cropPrefix}_sow_area`] = crop.sowing_area;
                flattenedFarmer[`${cropPrefix}_season_desc`] = crop.season_desc;
                flattenedFarmer[`${cropPrefix}_seed_supported`] = crop.seed_supported;
                flattenedFarmer[`${cropPrefix}_production`] = crop.harvesting;
                flattenedFarmer[`${cropPrefix}_agniastra`] = crop.agniastra;
                flattenedFarmer[`${cropPrefix}_neemastra`] = crop.neemastra;
                flattenedFarmer[`${cropPrefix}_trikodarma`] = crop.trichoderma;
                flattenedFarmer[`${cropPrefix}_income`] = crop.income;
                flattenedFarmer[`${cropPrefix}_consumption`] = crop.consumption;
            });

            return flattenedFarmer;
        });
    }

    function getCropData(inputData) {

        //
        const cropData = inputData.flatMap(item =>
            item.crop_list.map(crop => crop.crop_name.toLowerCase().replace(/\s/g, '_'))
        );
        const uniqueCropIds = [...new Set(cropData)];
        console.log("Unique crop IDs:", uniqueCropIds);
        //
        return uniqueCropIds;
    }

    const download_excel_handler = () => {
        if (tableData.length > 0) {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(tableData);
            XLSX.utils.book_append_sheet(wb, ws, "Mysheet1");
            XLSX.writeFile(wb, "agriculture_report.xlsx");
            let notify = notification({ message: "Excel downloaded successfully", type: 'success' });
            notify();
        } else {
            let notify = notification({ message: "No data found!", type: 'error' });
            notify();
        }
    }


    return (
        <>
            <Header />
            <Page title='Agriculture Report (Monthly)' breadcrumbs='Home / Livestock Report (Monthly)'>
                <Row className='p-3'>
                    {mode === "1" &&
                        <Col xs={12}>
                            <Card className='mb-3' color="primary" outline>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md={2} className="mb-2">
                                                <Label for="month">Month - Year</Label>
                                                <Input type="month" name="month" id="month" placeholder="Month" onChange={(e) => setSearchDate(e.target.value) } />
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white mt-4' color='primary' size="sm" onClick={fetch_livestock_data_handler} block> Search </Button>
                                            </Col>
                                            <Col md={2} className="mb-2">
                                                <Button className='text-white mt-4' color='primary' size="sm" onClick={download_excel_handler} block> Download Excel </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card className='' color="primary" outline>
                                <CardHeader>Agriculture Report <small className='text-muted'> </small></CardHeader>
                                <CardBody>
                                    <div className="overflow-auto">
                                        <table className="border-collapse table-auto">
                                            <thead>
                                                <tr className="bg-primary text-white">
                                                    <th className="border p-2">Farmer ID</th>
                                                    <th className="border p-2">Name</th>
                                                    <th className="border p-2">Reg No.</th>
                                                    <th className="border p-2">Village</th>
                                                    <th className="border p-2">Panchayat</th>
                                                    <th className="border p-2">Block</th>
                                                    <th className="border p-2">District</th>
                                                    {cropData?.map(crop => (
                                                        <React.Fragment key={crop}>
                                                            <th className="border p-2 bg-blue-50">{crop}</th>
                                                            <th className="border p-2">Sow Date</th>
                                                            <th className="border p-2">Sow Area</th>
                                                            <th className="border p-2">Season</th>
                                                            <th className="border p-2">Seed Supported</th>
                                                            <th className="border p-2">Production</th>
                                                            <th className="border p-2">Agniastra</th>
                                                            <th className="border p-2">Neemastra</th>
                                                            <th className="border p-2">Trikodarma</th>
                                                            <th className="border p-2">Income</th>
                                                            <th className="border p-2">Consumption</th>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData?.map((farmer, index) => (
                                                    <tr key={farmer.farmer_id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                                                        <td className="border p-2">{farmer.farmer_id}</td>
                                                        <td className="border p-2">{farmer.farmer_name}</td>
                                                        <td className="border p-2">{farmer.reg_no}</td>
                                                        <td className="border p-2">{farmer.village}</td>
                                                        <td className="border p-2">{farmer.panchayat}</td>
                                                        <td className="border p-2">{farmer.block}</td>
                                                        <td className="border p-2">{farmer.district}</td>
                                                        {cropData?.map(crop => (
                                                            <React.Fragment key={`${farmer.farmer_id}-${crop}`}>
                                                                <td className="border p-2 bg-blue-50">{farmer[`${crop}_crop_name`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_sow_date`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_sow_area`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_season_desc`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_seed_supported`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_production`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_agniastra`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_neemastra`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_trikodarma`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_income`]}</td>
                                                                <td className="border p-2">{farmer[`${crop}_consumption`]}</td>
                                                            </React.Fragment>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    }

                </Row>

            </Page>

            <Footer />
            {loading && <Loader />}

        </>
    )

}

export default MonthlyAgricultureReport;