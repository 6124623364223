import React from 'react';
import { Row, Col } from 'reactstrap';

const Loader = () => {
    return(
        <div id='loader'>
           <span class="loader text-primary"></span>
        </div>
    )
}
export default Loader;